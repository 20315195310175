/******** NAVIGATION *******/

/**
 * Minimiert die Navigation beim Scrollen
 * Wendet einen alternativen Farbstil an, wenn die Navigation über einem Bereich mit derselben Farbe zu sehen ist
 * Super-Dropdown-Funktionalität
 */
export default function Navigation() {
  let navigation = document.querySelector('.navigation');
  let navigationRect = navigation.getBoundingClientRect();
  let superdropdowns = document.querySelectorAll('.navigation__dropdown--type-superdropdown');

  function open() {
    navigation.classList.add('navigation--animateable');
    navigation.classList.add('navigation--state-open');
  }

  function close() {
    navigation.classList.add('navigation--animateable');
    navigation.classList.remove('navigation--state-open');
  }

  navigation.querySelector('.navigation__open').addEventListener('click', open);
  navigation.querySelector('.navigation__close').addEventListener('click', close);

  superdropdowns.forEach(function (superdropdown) {
    superdropdown.querySelectorAll('.navigation__dropdown__item').forEach(function (item) {
      item.addEventListener('mouseover', function (event) {
        let item = event.target;

        for (let i = 0; i < 3; i++) {
          if (item.hasAttribute("data-superdropdown-id"))
            break;

          item = item.parentNode;
        }

        superdropdown.querySelectorAll('.navigation__dropdown__description').forEach(function (description) {
          if (description.id == item.getAttribute('data-superdropdown-id')) {
            description.classList.add('navigation__dropdown__description--state-visible');
          } else {
            description.classList.remove('navigation__dropdown__description--state-visible');
          }
        });
      });
    });
  });

  let ticking = false;

  function updateStates() {
    if (!ticking) {
      ticking = true;
      window.requestAnimationFrame(function () {
        let blocks = document.querySelectorAll(".navigation--alternative");
        let alternativeStyle = false;

        for (let i = 0; i < blocks.length; i++) {
          let blockRect = blocks[i].getBoundingClientRect();
          if (blockRect.top <= navigationRect.bottom && blockRect.bottom >= navigationRect.top) {
            alternativeStyle = true;
            break;
          }
        }

        if (alternativeStyle) {
          navigation.classList.add('navigation--alternative-style');
        } else {
          navigation.classList.remove('navigation--alternative-style');
        }

        if (window.scrollY > 100) {
          if (!navigation.classList.contains('navigation--state-minified')) {
            navigation.classList.add('navigation--animateable');
            navigation.classList.add('navigation--state-minified');
          }
        } else {
          if (navigation.classList.contains('navigation--state-minified')) {
            navigation.classList.add('navigation--animateable');
            navigation.classList.remove('navigation--state-minified');
          }
        }

        ticking = false;
      });
    }
  }

  navigation.addEventListener("transitionend", function () {
    navigation.classList.remove('navigation--animateable');
  }, false);

  window.addEventListener('scroll', updateStates);

  updateStates();

  return {
    open: open,
    close: close
  };
}
