/**
 * Globale API im Frontend fürs Tracking
 *
 * Tracking der Benutzer inkl. DSGVO-Wrapper für die datenschutzkonforme
 * Nutzung des Trackings.
 *
 * @var tracking  API fürs Tracking
 *
 * @api function try         Versuchen das Tracking zu starten
 * @api function isEnabled   Gibt zurück, ob das Tracking für die Seite aktiviert ist
 * @api function isAllowed   Gibt zurück, ob das Tracking vom User erlaubt wurde
 * @api function setAllowed  Setzt die Einverständnis des Users übers Tracking
 *
 * @state clean
 */
export default function tracking() {

	function isEnabled() {
		console.debug('Tracking enabled: ' + window.trackingEnabled);
		return window.trackingEnabled;
	}

	// Einstellung aus Craft CMS
	function getService() {
		console.debug('Tracking service: ' + window.trackingService)
		return window.trackingService;
	}

	// Einstellung aus Craft CMS
	function getEndpointUrl() {
		console.debug('Tracking endpoint: ' + window.trackingEndpoint)
		return window.trackingEndpoint;
	}

	// Einstellung aus Craft CMS
	function getSiteId() {
		console.debug('Tracking site ID: ' + window.trackingSiteId)
		return window.trackingSiteId;
	}

	function start() {
		if (!isEnabled())
			return false;

		switch(getService()) {
			case 'matomo':
				var _paq = window._paq || [];
				_paq.push(['setTrackerUrl', getEndpointUrl() + '/matomo.php']);
				_paq.push(['setSiteId', getSiteId()]);
				_paq.push(['disableCookies']);
				_paq.push(['trackPageView']);
				_paq.push(['enableLinkTracking']);
				window._paq = _paq;

				var script = document.createElement('script');
				script.type = 'text/javascript';
				script.async = true;
				script.defer = true;
				script.src = getEndpointUrl() + '/matomo.js';
				document.querySelector('head').appendChild(script);

				console.debug('Matomo tracking started');
				break;

			default:
				return false;
		}

		return true;
	}

	start();

	return {
		try: start,
		isEnabled: isEnabled
	};
};
