import Navigation from '@components/navigation';
import tracking from '@lib/tracking.js';
//import Footer from '@components/footer';
import HintEvaluator from '@components/hints.js';

import '@css/main.pcss';

(function () {
  Navigation();
  tracking();

  const dsgvoWrappers = [...document.querySelectorAll('.dsgvo-wrapper')];

  if (dsgvoWrappers.length > 0) {
    import('./components/dsgvo-wrapper.js').then(
      ({ default: DSGVOWrapper }) => {
        dsgvoWrappers.map((wrapper) => {
          return new DSGVOWrapper(wrapper);
        });
      }
    );
  }

  const socialMediaBlocks = [
    ...document.querySelectorAll('.block--type-social-media'),
  ];

  if (socialMediaBlocks.length > 0) {
    import('./blocks/social-media.js').then(({ default: SocialMediaBlock }) => {
      socialMediaBlocks.map((block) => {
        return new SocialMediaBlock(block);
      });
    });
  }

  const scrollControllers = [
    ...document.querySelectorAll('.block__content--horizontal-scroll'),
  ];

  if (scrollControllers.length > 0) {
    import('./lib/scroll-controller.js').then(
      ({ default: ScrollController }) => {
        scrollControllers.map((element) => {
          const controller = new ScrollController(
            element,
            element.querySelector('.block__scroll-container'),
            {
              getDelta: function () {
                return document.body.clientWidth / 2;
              },
            }
          );

          if (controller.updateDelta !== undefined) {
            element.addEventListener('resize', controller.updateDelta);
          }

          return controller;
        });
      }
    );
  }

  /** Show hints for sliders **/

  const slider = document.querySelector('.page-header--type-slider');

  if (slider) {
    const hint = slider.querySelector('.hint--slider-swipe');

    const evaluator = new HintEvaluator('slider-swipe', {
      getHint: function () {
        return hint;
      },
      dependencies: function () {
        return document.body.clientWidth <= 900;
      },
      success: function () {
        return window?.slider?.getSwipeHintSuccess();
      },
    });

    slider.addEventListener('touchstart', evaluator.trigger, { passive: true });
    slider.addEventListener('touchend', evaluator.checkSuccess, {
      passive: true,
    });
  }
})();
