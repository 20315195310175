/**
 * Diese Skript blendet Nutzungshinweise für Menschen ein, die es gebrauchen könnten
 */

/**
 * Beurteilt, ob ein Hinweis gebraucht wird
 */
export default function HintEvaluator(id, binding) {
  function evaluate() {
    let result;
    let saved = localStorage.getItem('hint-' + id);

    if (saved != null) {
      result = false;
    } else if ((binding.dependencies === undefined || binding.dependencies()) && (binding.evaluate === undefined || binding.evaluate())) {
      result = true;
    } else {
      result = false;
    }

    if (result) {
      binding.getHint().classList.add('hint--visible');

      setTimeout(function() {
        binding.getHint().classList.remove('hint--visible');
      }, 5000);

      localStorage.setItem('hint-' + id, 'true');
    }
  }

  return {
    trigger: evaluate,
    checkSuccess: function() {
      if (binding.success())
        binding.getHint().classList.remove('hint--visible');
    }
  }
}
